import { useParams } from "react-router";

export const AdministrationMain = () => {
  const params = useParams();

  return (
    <div>
      <iframe
        style={{ width: "1200px", minHeight: "100vh" }}
        src={"https://klubb.bridge.no/" + params["*"]}
      ></iframe>
    </div>
  );
};

import PreviewIcon from "@mui/icons-material/Preview";
import { DMEditorView } from "dmeditor";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export const ArticlePreview = (props) => {
  const {
    params: { content },
  } = props;

  const [shown, setShown] = useState(false);
  const handle = (e) => {
    e.preventDefault();
    setShown(true);
  };

  const valueStyle = {
    background: "#f6f6f6",
    padding: 5,
    border: "1px solid #f0f0f0",
    borderRadius: 4,
  };

  return (
    <span>
      <a href="#" onClick={handle} title="Preview">
        <PreviewIcon />
      </a>
      <Modal show={shown} onHide={() => setShown(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {content.body && (
            <div>
              {content.coverimage && (
                <div>
                  <div>Cover image:</div>
                  <div style={valueStyle}>
                    <img src={content.coverimage} width="200px" />
                  </div>
                </div>
              )}
              <div>
                <div>Comment: </div>
                <div style={valueStyle}>{content.comment}</div>
              </div>
              <div>Summary:</div>
              <div style={valueStyle}>
                <div dangerouslySetInnerHTML={{ __html: content.summary }} />
              </div>
              <div>Body: </div>
              <div style={valueStyle}>
                <div dangerouslySetInnerHTML={{ __html: content.body }} />
              </div>
            </div>
          )}
          {shown && <DMEditorView data={content.fullbody || []} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShown(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};

import { FetchWithAuth } from "digimaker-ui/util";
import { setSiteEnv } from "shared/src/widgets/siteEnv";

export const initSite = async (sites, rootID) => {
  const selected = sites.find((item) => item.location.id === rootID);
  const root = selected.location.id;
  const data = await FetchWithAuth(
    "content/list/folder?parent=" + root + "&level=1&limit=-1"
  );

  if (data.error === false) {
    const folders = data.data.list.filter(
      (item) => item.folder_type && item.folder_type.value
    );
    const updateFolders: Array<any> = [];
    for (const item of folders) {
      item.folder_type = item.folder_type.value;
      updateFolders.push(item);
    }

    const siteType = selected["_club"]["site_type"];
    setSiteEnv(updateFolders, {
      clubID: siteType === "club" ? selected["_club"]["org_id"] : 0,
      kretsID:
        siteType === "club"
          ? selected["_club"]["parent_org_id"]
          : siteType === "krets"
          ? selected["_club"]["org_id"]
          : 0,
      basePath: selected["_club"]["basePath"],
    });
  }
};

import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";
import { EntityArticleInfo } from "./entity";
const definition: DME.Widget = {
  category: "bridge",
  icon: "bridge",
  name: "Article detail",
  widgetType: "mixed",
  type: "bridge-article-detail",
  events: {
    createBlock: (): DMEData.Block<EntityArticleInfo> => {
      return {
        id: nanoid(),
        type: "bridge-article-detail",
        data: {},
        children: [
          {
            id: nanoid(),
            style: { _: "article-summary" },
            data: {
              value: [{ type: "paragraph", children: [{ text: "Summary" }] }],
            },
            type: "text",
          },
          {
            id: nanoid(),
            data: {
              value: [{ type: "paragraph", children: [{ text: "Body" }] }],
            },
            type: "text",
          },
        ],
      };
    },
    updateData: () => {},
  },
  settings: [
    {
      name: "Image",
      property: ".image",
      settingComponent: "article-image",
      custom: true,
    },
  ],
};

export default definition;

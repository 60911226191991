import styled from "@emotion/styled";

export const ArticleDetailContainer = styled.div`
  &.contentBox {
    border-top: 1px solid #f0f0f0;
  }
  &.contentBox .contentHeader {
    padding: 20px 0px 30px;
    color: #333333;
  }
  &.contentBox .contentHeader .title {
    font-size: var(--first-level-title-font-size);
    &:focus {
      outline: 2px solid var(--dmee-selected-border-color);
    }
  }
  &.contentBox .contentHeader .auth {
    font-size: 18px;
    color: #999;
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }
  .dme-w-detailImage {
    margin: 20px 0;
    text-align: center;
    img {
      max-width: 65%;
      max-height: 600px;
    }
  }

  .detailcontent {
    padding-bottom: 10px;
  }

  .dme-viewmode-mobile & .dme-w-detailImage img {
    max-width: 100%;
  }

  .contentFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0 30px;
  }
  .sharebox {
    display: flex;
    justify-content: flex-end;
  }
  .backbtn {
    width: 120px;
  }
  .backbtn:hover {
    background-color: #198754 !important;
    border-color: #198754 !important;
  }

  .sharebox .w-icon {
    display: block;
    width: 52px;
    height: 52px;
    margin-right: 30px;
    border-radius: 50%;
    cursor: pointer;
  }
  .sharebox .w-icon:hover {
    border: 2px solid #009764;
    transform: scale(1.1);
  }
  .sharebox .w-icon:nth-of-type(4n + 0) {
    margin-right: 0;
  }
  .facebookicon {
    background: url("../public/images/facebookicon.png") no-repeat center;
    background-size: 100% 100%;
  }
  .twitterIcon {
    background: url("../public/images/twitterIcon.png") no-repeat center;
    background-size: 100% 100%;
  }
  .cameraIcon {
    background: url("../public/images/cameraIcon.png") no-repeat center;
    background-size: 100% 100%;
  }
  .emailIcon {
    background: url("../public/images/emailIcon.png") no-repeat center;
    background-size: 100% 100%;
  }
`;

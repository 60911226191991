import { DME, useEditorStore, BlockListRender, DMEData } from "dmeditor";
import { EntityArticleInfo } from "./entity";
import { css } from "@emotion/css";
import Moment from "moment";
import { useCallback } from "react";

import { ArticleDetailContainer } from "./styled";
import { imagePath } from "../util";

const ArticleDetail = (props: DME.WidgetRenderProps<EntityArticleInfo>) => {
  const {
    blockNode: { data, children = [] },
    path,
  } = props;
  const { publishedDate, image } = data;
  const { page, updatePage } = useEditorStore();

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLHeadingElement>) => {
      updatePage(e.target.innerText, "title");
    },
    []
  );

  return (
    <ArticleDetailContainer className={`contentBox detail`}>
      <div className="w-container">
        <div className={css`` + " contentHeader"}>
          <h2
            className="title-with-strip font-Cambria-blod text-center"
            contentEditable={props.mode == "edit"}
            onBlur={handleTitleChange}
          >
            {props.mode === "edit" ? page.title || "" : data.title}
          </h2>
          <div className="auth">
            <div>
              <span>
                {publishedDate || Moment().format("DD.MM.YYYY HH:mm")}
              </span>
            </div>
          </div>
        </div>
        {image && image.src && (
          <div className="dme-w-detailImage">
            <img src={imagePath(image.src)} alt="" />
          </div>
        )}
        <div className="detailcontent">
          <BlockListRender
            blockData={children as DMEData.BlockList}
            path={path}
            mode={props.mode}
          />
        </div>
      </div>
    </ArticleDetailContainer>
  );
};

const onServerSideLoad = async (blockData: any, params) => {
  const { metadata } = params;
  blockData.data.title = metadata.name;
  blockData.data.publishedDate = Moment(metadata.published).format(
    "DD.MM.YYYY HH:mm"
  );
};

export { ArticleDetail, onServerSideLoad };

import { useParams } from "react-router";
import { useEffect, useMemo, useRef, useState } from "react";
import { FetchWithAuth } from "digimaker-ui/util";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Alert, Button, Container, Overlay } from "react-bootstrap";
import { ContentLibrary } from "./ContentLibrary";
import useSiteStore from "../../store/useSiteStore";
import { DMEditorView } from "dmeditor";
import { Delete } from "digimaker-ui/actions";

export const MainContent = (props: {}) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [content, setContent] = useState(null as any);
  const { setCurrentContent, user } = useSiteStore();
  const [urlShown, setUrlShown] = useState(false);
  const urlShowButton = useRef(null);
  const {
    site: { host, basePath },
  } = useSiteStore();

  useEffect(() => {
    FetchWithAuth("content/get/" + params.id).then((resp: any) => {
      if (resp.error === false) {
        const content = resp.data;
        setContent(content);
        setCurrentContent(content);
      } else {
        setCurrentContent(null);
        setContent(null);
      }
    });
    setUrlShown(false);
  }, [params.id]);

  const jumpTo = (id: any) => {
    navigate(`/fulledit/${params.id}`);
  };

  const canDelete = useMemo(() => {
    if (content) {
      const roles = user.roles;
      if (roles.includes("bridge-admin")) {
        return true;
      } else {
        return content.metadata.author === user.id;
      }
    }
    return false;
  }, [content]);

  if (!content) {
    return <div>...</div>;
  }

  const getUrlPath = () => {
    return content.location.identifier_path.split("/").slice(4).join("/");
  };

  return (
    <div>
      {content && ["page", "site"].includes(content.metadata.contenttype) && (
        <div>
          <div className="text-right">
            <Link
              to={
                content.link
                  ? "/website/edit/" + params.id
                  : "/fulledit/" + params.id
              }
            >
              <Button
                variant="outline-link"
                size="sm"
                onClick={() => jumpTo(params.id)}
              >
                <i className="bi bi-pencil"></i> Rediger side
              </Button>
            </Link>
            <Button
              ref={urlShowButton}
              variant="link"
              onClick={() => setUrlShown(!urlShown)}
            >
              <i className="bi bi-link-45deg"></i>Vis url
            </Button>
            <Overlay
              target={urlShowButton.current}
              show={urlShown}
              placement="bottom"
              rootClose={true}
              onHide={() => setUrlShown(false)}
            >
              <div
                style={{
                  marginTop: 5,
                  padding: 10,
                  maxWidth: 300,
                  border: "1px solid #dddddd",
                  background: "white",
                }}
              >
                <div>
                  <div>Intern url:</div>
                  <input
                    type="text"
                    size={25}
                    onFocus={(e) => e.target.select()}
                    readOnly
                    value={"./" + getUrlPath()}
                  />
                </div>
                <div style={{ marginTop: 4 }}>
                  <div>Absolutt url:</div>
                  <input
                    type="text"
                    size={25}
                    onFocus={(e) => e.target.select()}
                    readOnly
                    value={
                      "https://" +
                      (host || "www.bridge.no/" + basePath) +
                      "/" +
                      getUrlPath()
                    }
                  />
                </div>
                <br />
                <Alert>
                  Merk: anbefaler å bruke intern url når det er en lenke fra
                  intern siden, slik at koblingen ikke påvirkes av
                  domeneendringer.
                </Alert>
              </div>
            </Overlay>
            {content.metadata.contenttype !== "site" && canDelete && (
              <Delete
                fromview="content"
                params={{
                  content: content,
                  afterAction: () => {
                    navigate("/website");
                  },
                }}
              />
            )}
          </div>
          <div
            className={
              "preview-dmeditor contenttype-" + content.metadata.contenttype
            }
          >
            <div className="dmeditor-container">
              <DMEditorView data={content.full_body || []} />
            </div>

            {content.link && (
              <div style={{ padding: 20 }}>
                <label>Lenke:</label>
                <div>
                  <a href={content.link} target="_blank">
                    {content.metadata.name}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {content &&
        content.metadata.contenttype === "folder" &&
        [
          "article",
          "image",
          "file",
          "template",
          "event",
          "annual_report",
          "link",
          "block",
        ].includes(content.folder_type.value) && (
          <ContentLibrary
            key={content.location.id}
            content={content}
            categoryID={
              searchParams.has("tab")
                ? parseInt(searchParams.get("tab") as string)
                : undefined
            }
          />
        )}
    </div>
  );
};

import FileUpload from "digimaker-ui/FileUpload";
import { Style } from "./Style";
import { useEffect } from "react";
import { useState } from "react";
import { FetchWithAuth } from "digimaker-ui/util";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

const Create = (props: { id: string }) => {
  const nav = useNavigate();
  const [params, setParams] = useSearchParams();
  const from = params.get("from");
  const [year, setYear] = useState("");
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);

  const save = (data) => {
    if (year === "") {
      alert("Please select a year");
      return;
    }
    if (!file1 || !file2 || !file3 || !file4) {
      window.alert("Please upload all files before submitting.");
      return;
    }
    const jsonData = {
      year: data.page.year,
      body: [
        { name: "audit", path: file1 },
        { name: "test2", path: file2 },
        { name: "test2", path: file3 },
        { name: "test2", path: file4 },
      ],
    };
    FetchWithAuth("content/create/annual_report/" + props.id, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(jsonData),
    })
      .then(() => {
        nav(from ? "/website" + from : "/");
      })
      .catch((d) => {
        window.alert(d.message);
        nav(`/`);
      });
  };

  return (
    <form
      style={{ ...Style.container, flexDirection: "column" }}
      onSubmit={(e) => {
        e.preventDefault();
        save({ page: { year: year } });
      }}
    >
      <h2>Create Annual Report</h2>

      <select
        style={Style.select}
        id="year"
        name="year"
        value={year}
        onChange={(e) => setYear(e.target.value)}
      >
        <option value="">Please select year</option>
        <option value="2020">2020</option>
        <option value="2021">2021</option>
        <option value="2022">2022</option>
        <option value="2023">2023</option>
        <option value="2024">2024</option>
      </select>

      <div>
        <label style={Style.label} htmlFor="file1">
          Styrets beretning
        </label>
        <div>
          <FileUpload
            value={""}
            name={"file1"}
            service="content"
            format={"pdf"}
            onSuccess={(info) => {
              console.log(info);
              setFile1(info.nameUploaded);
            }}
          />
        </div>
      </div>

      <div>
        <label style={Style.label} htmlFor="file1">
          Årsregnskap
        </label>
        <div>
          <FileUpload
            value={""}
            name={"file2"}
            service="content"
            format={"pdf"}
            onSuccess={(info) => {
              setFile2(info.nameUploaded);
            }}
          />
        </div>
      </div>

      <div>
        <label style={Style.label} htmlFor="file1">
          Revisjonsberetning
        </label>
        <div>
          <FileUpload
            value={""}
            name={"file3"}
            service="content"
            format={"pdf"}
            onSuccess={(info) => {
              setFile3(info.nameUploaded);
            }}
          />
        </div>
      </div>

      <div>
        <label style={Style.label} htmlFor="file1">
          Årsmøteprotokoll
        </label>
        <div>
          <FileUpload
            value={""}
            name={"file4"}
            service="content"
            format={"pdf"}
            onSuccess={(info) => {
              setFile4(info.nameUploaded);
            }}
          />
        </div>
      </div>

      <br />
      <div>
        <Button size="sm" type="submit" name="submit">
          Submit
        </Button>
        &nbsp;
        <Button
          size="sm"
          variant="light"
          onClick={() => nav(from ? "/website" + from : "/")}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default Create;

import { registerSettingComponent, registerWidget } from "dmeditor";
import definition from "./definition";
import { MemberList, onServerSideLoad } from "./MemberList";

const register = () => {
  registerWidget(definition, {
    render: MemberList,
    onServerSideLoad: onServerSideLoad,
  });
};

export default register;

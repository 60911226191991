import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";
import { EntityClubMembers } from "./entity";
const definition: DME.Widget = {
  category: "bridge",
  icon: "bridge",
  name: "Club members countdown",
  type: "bridge-club-members-countdown",
  events: {
    createBlock: (): DMEData.Block<EntityClubMembers> => {
      return {
        id: nanoid(),
        type: "bridge-club-members-countdown",
        data: {
          title: "MEDLEMMER",
          settings: {},
        },
      };
    },
    updateData: () => {},
  },
  settings: [
    {
      name: "Title",
      property: ".title",
      settingComponent: "input",
    },
  ],
};

export default definition;

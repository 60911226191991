import { registerSettingComponent, registerWidget } from "dmeditor";
import definition from "./definition";
import { Result, onServerSideLoad } from "./Result";

const register = () => {
  registerWidget(definition, {
    render: Result,
    onServerSideLoad: onServerSideLoad,
  });
};

export default register;

import { registerSettingComponent, registerWidget } from "dmeditor";
import definition from "./definition";
import { Links, onServerLoad } from "./Links";
import { LinkSettings } from "./settings/LinksSetting";

const register = () => {
  registerWidget(definition, { render: Links, onServerSideLoad: onServerLoad });
  registerSettingComponent("bridge-links", LinkSettings);
};

export default register;

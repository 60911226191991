import { Node } from "slate";

export const getNiceUrl = (identifierPath: string) => {
  let arr = identifierPath.split("/");
  return arr.slice(2).join("/");
};

export const getArticleNiceUrl = (location: any) => {
  let niceUrl = getNiceUrl(location.identifier_path);
  return "/detail/" + niceUrl + "/" + location.id;
};

export const getIdentifierPath = (path: string) => {
  return "content/hjerter-ess/" + path;
};

export const jsonToHtml = (nodes: any[] | null) => {
  if (!nodes) {
    return "";
  }
  return nodes.map((n) => Node.string(n)).join("<br />");
};

export const getArticleSummaryFromList = (blocklist) => {
  let result = "";

  if (
    blocklist &&
    blocklist.length > 0 &&
    blocklist[0].type === "bridge-article-detail"
  ) {
    for (const item of blocklist[0].children) {
      if (
        item.type === "text" &&
        item.style &&
        item.style["_"] === "article-summary"
      ) {
        result = jsonToHtml(item.data.value);
        break;
      }
    }
  }

  return result;
};

import {
  Main,
  DMInit,
  List,
  ContentActionParams,
  ViewContent,
} from "digimaker-ui";
import { getListConfig } from "../../Config";
import { useEffect, useState } from "react";
import { FetchWithAuth } from "digimaker-ui/util";
import { Container } from "react-bootstrap";
import useSiteStore from "../../store/useSiteStore";
import { nbfEnv, siteEnv } from "shared/src/widgets/siteEnv";

export const Footer = () => {
  const [content, setContent] = useState(null);
  const { setCurrentContent } = useSiteStore();

  useEffect(() => {
    FetchWithAuth("content/get/" + nbfEnv.folderFooter).then((resp: any) => {
      if (resp.error === false) {
        const content = resp.data;
        setContent(content);
      } else {
      }
    });
    setCurrentContent(null);
  }, []);

  return (
    <Container>
      <h2 className="title">Bunntekst</h2>
      <List
        id={nbfEnv.folderFooter}
        contenttype="block"
        {...(getListConfig(content, "block") as any)}
        viewmode="list"
      />
    </Container>
  );
};

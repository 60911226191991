import styled from "@emotion/styled";

export const SponsorContainer = styled.div`
  width: var(--dme-container-width);
  margin-left: calc((var(--dme-main-width) - var(--dme-container-width)) / 2);

  .sponsor {
    height: auto;
    padding: 50px 0;
    background: url("/images/bg_links.jpg") no-repeat center;
    background-size: 100% 100%;
    display: flex;
  }
  .title {
    font-size: var(--first-level-title-font-size);
  }
  .sponsor .title {
    color: #fff;
  }
  .sponsor .title::after {
    content: "";
    display: block;
    width: 4.69rem;
    height: 5px;
    margin: 15px auto 0px;
    background: var(--waring-color);
  }
  .sponsor-group {
    display: flex;
    margin-top: 40px;
    /* justify-content: space-between; */
  }
  .sponsor-group a {
    margin-right: 20px;
    width: 45%;
  }

  img {
    max-width: 100%;
  }

  .sponsor-group img {
    width: 100%;
    /* max-height: 114px; */
    object-fit: contain;
  }
  /* .sponsor-group img:nth-of-type(3n+0){
    margin-right:0;
  } */
  .sponsor-group-item {
    width: 55%;
    display: flex;
    justify-content: space-between;
  }
  .sponsor-group-item a {
    width: 50%;
  }
  .sponsor-group-item a:last-child {
    margin: 0;
  }
  .friendlink {
    background: #f0f0f0;
    padding-bottom: 30px;
  }
  .friendlink-group {
    width: var(--dme-main-width);
    margin: auto;
    display: flex;
  }
  .friendlink-group > div {
    margin: auto;
    width: 33.33%;
    padding: 20px 10px;
    text-align: center;
  }
  .friendlink-group a:last-child {
    margin: 0;
  }
  .friendlink-group img {
    max-width: 90%;
    max-height: 116px;
    object-fit: contain;
  }

  .dmeditor-view.dme-viewmode-mobile & {
    .sponsor {
      height: auto;
      padding: 10px;
    }
    .sponsor .title {
      font-size: 1.25rem;
      padding: 20px 0;
    }
    .sponsor-group {
      flex-direction: column;
      margin: 0;
    }
    .sponsor-group a {
      width: 100%;
      margin-bottom: 10px;
    }
    .sponsor-group-item {
      width: 100%;
      display: flex;
    }

    .friendlink-group > div {
      width: 50%;
    }

    .friendlink-group img {
      max-height: 90px;
      object-fit: contain;
    }

    .friendlink {
      height: auto;
    }
  }
`;

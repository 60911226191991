//Share link rendering between react-browse-route and nextjs

import { ReactElement } from "react";

export let Link = (props: {
  to: string;
  className?: string;
  children: any;
  key?: string;
  newTarget?: boolean;
}) => (
  <a
    href={props.to}
    className={props.className || ""}
    {...(props.newTarget ? { target: "_blank" } : {})}
    {...(props.key ? { key: props.key } : {})}
  >
    {props.children}
  </a>
);

export const setLink = (
  LinkCom: (props: {
    to: string;
    className?: string;
    children: any;
    key?: string;
  }) => ReactElement
) => {
  Link = LinkCom;
};

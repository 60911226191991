import { Outlet, useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { ChangeSite } from "../../../components/ChangeSite";
import { Logout } from "../../../components/Logout";
import useSiteStore from "../../../store/useSiteStore";
import util from "digimaker-ui/util";
import { siteEnv } from "shared/src/widgets/siteEnv";

export const Layout = () => {
  const {
    site: { id: siteID, name: siteName, host, basePath },
    user,
  } = useSiteStore();
  const nagivate = useNavigate();

  return (
    <div>
      <header className="App-header">
        <div className="header-right">
          <span>
            <Logout />
          </span>
          <ChangeSite />
          <span>
            <a
              href={"https://" + (host || "test.bridge.no/" + basePath)}
              target="_blank"
              rel="noreferrer"
              style={{ color: "white" }}
            >
              <i className="bi bi-box-arrow-up-right"></i> Se Nettsiden
            </a>
          </span>
          {user.roles.includes("bridge-admin") && (
            <span>
              <a
                href={
                  "https://bridgeadmin.bridge.no/login?token=" +
                  util.getRefreshToken()
                }
                style={{ color: "white" }}
              >
                <i className="bi bi-box-arrow-up-right"></i> Bridge admin
              </a>
            </span>
          )}
          <span>{user.metadata.name}</span>
        </div>
        <h1>
          <Link to="/website" style={{ color: "white" }} title={siteID + ""}>
            {siteName}
          </Link>
        </h1>
        <div className="header-menu">
          <span>
            <NavLink to="website">Hjemmeside</NavLink>
          </span>
          {siteEnv.clubID > 0 && (
            <span>
              <NavLink to="/klubb">
                {" "}
                {/*klubb */}
                Klubbadmin
              </NavLink>
            </span>
          )}{" "}
          {siteEnv.kretsID > 0 && siteEnv.clubID === 0 && (
            <span>
              <NavLink to="/krets"> Kretsadmin</NavLink>
            </span>
          )}
          <span>
            <NavLink to="/administration">
              {/*klubb */}
              Oppsett
            </NavLink>
          </span>
        </div>
      </header>
      <div className="App-main">
        <Outlet />
      </div>
      <div className="footer">
        2023 (c) Norsk Bridgeforbund <br />
        Sognsveien 75 A, 0855 Oslo <br />
        bridge@bridge.no <br />
        <br />
        Delivered by <a href="https://digimaker.no">Digimaker AS</a>
      </div>
    </div>
  );
};

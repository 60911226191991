import { css } from "@emotion/css";

export const annualMeetingStyles = {
  widget: css`
    width: 100%;
  `,

  container: css`
    margin-bottom: 10px;
  `,
  header: css`
    text-align: center;
    font-weight: bold;
    padding: 10px;
  `,
  accordion: css`
    background: #f0f0f0;
  `,
  accordionSummary: css`
    background-color: #f3f3f3;
    &:hover {
      background-color: #e9e9e9;
    }
  `,
  accordionDetails: css`
    background-color: #fff;
  `,
  accordionActions: css`
    justify-content: flex-end;
    padding: 10px;
  `,

  h3: css`
    font-size: 1.3rem;
    margin-bottom: 0px;
  `,

  rowContainer: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  `,
  label: css`
    font-size: 18px;
    padding: 8px 12px;
    border-radius: 4px;
    text-align: center;
  `,
  link: css`
    padding: 8px 12px;
    border-radius: 4px;
    text-align: center;
  `,
};

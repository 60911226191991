import { Alert, Button, Container, Table } from "react-bootstrap";
import useSiteStore from "../../store/useSiteStore";
import { FetchWithAuth } from "digimaker-ui/util";
import { useEffect } from "react";

export const ForwardEmail = () => {
  return (
    <div>
      <h2>Email forwarding</h2> <br />
      <Container>
        <Alert variant="warning">Coming soon</Alert>
      </Container>
    </div>
  );
};

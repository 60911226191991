import React, { useState, useRef } from "react";
import { Alert, Button, Form, FormSelect, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import util from "digimaker-ui/util";

export const Login = () => {
  const [isloading, setIsloading] = useState(false);
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [siteID, setSiteID] = useState<number | undefined>();
  const [selectList, setSelectList] = useState<
    Array<{ orgNo: number; name: string; siteID: number }>
  >([]);

  const login = (siteID?: number) => {
    const dataObject = { username, password, siteID };
    setIsloading(true);
    fetch(process.env.REACT_APP_BRIDGE_API_URL + "/clubadmin/club_login", {
      method: "POST",
      body: JSON.stringify(dataObject),
    })
      .then((resp) => resp.json())
      .then((data) => {
        setIsloading(true);
        if (data.error === false) {
          const refreshToken = data.refreshToken;
          const userType = data.userType;
          const clubForm = new FormData();
          clubForm.set("Login", username);
          clubForm.set("Password", password);
          clubForm.set("RedirectURI", "/");

          let host = "klubb.bridge.no";
          if (userType === "krets") {
            host = "krets.bridge.no";
          }
          fetch("https://" + host + "/bridgemodule/klubb_login", {
            method: "POST",
            redirect: "manual",
            body: clubForm,
            credentials: "include",
          }).then((respClub) => {
            if (respClub.type === "opaqueredirect") {
              util.setRefreshToken(refreshToken, "/");
              window.location.href = "/website";
            }
          });
        } else {
          setError(data.data);
          emptyList();
        }
      });
  };

  const emptyList = () => {
    setSelectList([]);
    setSiteID(undefined);
  };

  const submit = async (event) => {
    let forms = event.currentTarget;
    setValidated(true);
    event.preventDefault();
    if (forms.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    setIsloading(true);
    setError("");
    fetch(process.env.REACT_APP_BRIDGE_API_URL + "/clubadmin/login_select", {
      method: "POST",
      body: JSON.stringify({ username, password }),
    })
      .then((res) => res.json())
      .then((data) => {
        setIsloading(false);
        if (data.error === false) {
          const list = data.data;
          if (list.length >= 2) {
            setSelectList(list);
          } else {
            login();
          }
        } else {
          setError(data.data);
        }
      });
  };

  return (
    <div id="login" style={{ margin: "auto" }}>
      <div className="login_left"></div>
      <div className="login_right">
        <div className="logo_image"></div>
        <div className="login">
          <h3
            className="text-center"
            style={{
              margin: "42px 0 30px 0",
              fontSize: "2.25rem",
            }}
          >
            Logg inn
          </h3>
          <Modal show={selectList.length > 0} centered>
            <Modal.Title>
              <div style={{ padding: 8 }}>Velg nettsted</div>
            </Modal.Title>
            <Modal.Body>
              <div>
                <FormSelect
                  onChange={(e) => setSiteID(parseInt(e.target.value))}
                >
                  <option>Vennligst velg nettsted </option>
                  {selectList.map((item) => (
                    <option value={item.siteID}>
                      {item.name} ({item.orgNo})
                    </option>
                  ))}
                </FormSelect>
                <br />
                <div>
                  <Button
                    variant="danger"
                    disabled={!siteID}
                    onClick={() => {
                      if (siteID) {
                        login(siteID);
                      }
                    }}
                  >
                    Bekreft
                  </Button>
                  <Button variant="text" onClick={emptyList}>
                    Avbryt
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Form onSubmit={submit} validated={validated} noValidate>
            <Form.Group className="mb-3 form-item" controlId="formUsername">
              <Form.Label
                style={{
                  fontSize: "1.13rem",
                }}
              >
                BRUKERNAVN
              </Form.Label>
              <div className="mb-3">
                <span className="icons userIcon"> </span>
                <Form.Control
                  name="username"
                  type="text"
                  placeholder="Vennligst skriv inn brukernavn"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Vennligst skriv inn brukernavn.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3  form-item"
              controlId="formBasicPassword"
            >
              <Form.Label
                style={{
                  fontSize: "1.13rem",
                }}
              >
                PASSORD
              </Form.Label>
              <div className="mb-3">
                <span className="icons passwrodIcon"> </span>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Skriv inn passord"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Skriv inn passord.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Button
              variant="danger"
              type="submit"
              style={{
                width: "100%",
                borderRadius: 0,
                background: "#C33625",
                marginTop: "50px",
                fontSize: "1.5rem",
              }}
            >
              Logg Inn
            </Button>
          </Form>
          <div style={{ textAlign: "right", marginTop: 10 }}>
            <a href="https://www.bridge.no/user/forgotpassword">
              Glemt passord?
            </a>
          </div>
          {isloading && <span className="loading"></span>}
          {error && (
            <div>
              <br />
              <Alert variant="danger">{error}</Alert>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import { Link } from "react-router-dom";
import { EntityLinks } from "./entity";
import { DME, useDevice } from "dmeditor";
import { SponsorContainer } from "./style";
import { useEffect, useState } from "react";
import { nbfEnv } from "../siteEnv";
import { FetchWithAuth } from "digimaker-ui/util";
import { imagePath, serverFetchWithAuth } from "../util";

const Links = (props: DME.WidgetRenderProps<EntityLinks>) => {
  const {
    blockNode: {
      data: { title, links },
    },
  } = props;

  const [nbfLinks, setNbfLinks] = useState(props.blockNode.data.nbfLinks || []);

  const fetchNBFLinks = () => {
    FetchWithAuth(
      "content/list/link?parent=" +
        nbfEnv.folderLink +
        "&sortby=priority%20desc"
    ).then((data) => {
      setNbfLinks(data.data.list);
    });
  };

  useEffect(() => {
    if (!props.blockNode.serverData) {
      fetchNBFLinks();
    }
  }, []);

  return (
    <SponsorContainer>
      <div>
        <div className="sponsor">
          <div className="w-container">
            <h2 className="title font-Cambria-blod text-center">
              {title.toUpperCase()}
            </h2>
            <div className="sponsor-group"></div>
          </div>
        </div>
        <div className="friendlink">
          <div className="friendlink-group">
            {nbfLinks.map((item: { image: string; link: string }) => (
              <div>
                <a href={item.link} target="_blank">
                  <img src={imagePath(item.image)} />
                </a>
              </div>
            ))}
            {links.map((item, index) => {
              return (
                <div>
                  <a
                    href={item.link.replaceAll(" ", "")}
                    key={index}
                    target="_blank"
                  >
                    <img src={imagePath(item.image)} />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </SponsorContainer>
  );
};

const onServerLoad = async (existingData, query) => {
  const parent = nbfEnv.folderLink;

  let bodyObj: any = `query{
        link(filter:{_location_parent_id:${parent}},sort: ["priority desc", "id desc"]){
            id,
            link,
            image,
            title,
          }                    
      }`;
  const data = await serverFetchWithAuth(bodyObj);
  const list = data.data.link;

  existingData.data.nbfLinks = list;
  existingData.serverData = true;
};

export { Links, onServerLoad };

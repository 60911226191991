import { nanoid } from "nanoid";

import { CalenderWidget } from "./CalenderWidget";
import { registerWidget } from "dmeditor";
import { onServerSideLoad } from "./CalenderWidget";

const registerCalenderWidget = () => {
  console.log("CalenderWidget:", CalenderWidget);
  registerWidget(
    {
      type: "bridge-calender",
      name: "Calendar",
      category: "bridge",
      icon: "bridge",
      settings: [
        {
          name: "Width",
          settingComponent: "input",
          property: "settings.width",
        },
        {
          name: "Height",
          settingComponent: "input",
          property: "settings.height",
        },
        {
          name: "Header",
          settingComponent: "input",
          property: "settings.header",
        },
        {
          name: "Position",
          settingComponent: "input",
          property: "settings.position",
        },
      ],

      events: {
        createBlock: () => ({
          id: nanoid(),
          type: "bridge-calender",
          data: {
            value: [],
            settings: {
              width: 800,
              height: 850,
              header: "Upcoming events",
              position: "right",
            },
          },
        }),
        updateData: () => void 0,
      },
    },
    { render: CalenderWidget, onServerSideLoad: onServerSideLoad }
  );
};

export default registerCalenderWidget;

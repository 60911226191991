export const pageSettings = [
  { identifier: "cover_image", name: "Cover image", type: "image" },
  { identifier: "meta_key", name: "Meta key", type: "text" },
  {
    identifier: "meta_description",
    name: "Meta description",
    type: "multitext",
  },
];

export const pageSettingsPage = [
  { identifier: "hide_in_menu", name: "Hide in menu", type: "checkbox" },
  { identifier: "meta_key", name: "Meta key", type: "text" },
  {
    identifier: "meta_description",
    name: "Meta description",
    type: "multitext",
  },
];

import { registerSettingComponent, registerWidget } from "dmeditor";
import definition from "./definition";
import { KretsInfo, onServerSideLoad } from "./KretsInfo";

const register = () => {
  registerWidget(definition, {
    render: KretsInfo,
    onServerSideLoad: onServerSideLoad,
  });
};

export default register;

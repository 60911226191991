import Browse from "digimaker-ui/Browse";
import { BrowseImageCallbackParams } from "dmeditor/core/config";
import { MultiUpload } from "digimaker-ui";
import { useState } from "react";
import { siteEnv } from "shared/src/widgets/siteEnv";

const BrowseImage = (props: {
  value: BrowseImageCallbackParams;
  onChange: (value: BrowseImageCallbackParams) => void;
  multiple?: boolean;
}) => {
  const [counter, setCounter] = useState(1);

  const onConfirmSelect = (v) => {
    let info: any[] = [];
    if (props.multiple) {
      for (const item of v) {
        info.push({ src: item.image, id: item.id });
      }
    } else {
      info = [{ src: v.image, id: v.id }];
    }

    props.onChange(info);
  };

  let selected: any = props.multiple ? [] : {};
  props.value.forEach((item) => {
    if (item.id) {
      if (props.multiple) {
        selected.push({ id: item.id, contenttype: "image", image: item.src });
      } else {
        selected = { id: item.id, contenttype: "image", image: item.src };
      }
    }
  });

  return (
    <div>
      <Browse
        inline={true}
        parent={siteEnv.folderImage}
        showTree={true}
        multi={props.multiple}
        trigger={true}
        key={counter}
        selected={selected}
        contenttype={["image"]}
        onConfirm={(value: any) => {
          onConfirmSelect(value);
        }}
      />
    </div>
  );
};

export { BrowseImage };

import React from "react";

const ErrorHandler = (props: { error }) => {
  const { error } = props;
  if (error.code === "0001") {
    window.location.href = "/login";
    return;
  }

  if (error) {
    return (
      <div className="error-main alert alert-warning">
        <h2>Feil</h2>
        <div>
          {error && (
            <div>
              {error.code === "0001" && (
                <div>
                  Du er logget ut
                  <div>
                    <a href="/login">Logg inn</a>
                  </div>
                </div>
              )}
              {error.message && <div>{error.message}</div>}
            </div>
          )}
        </div>
      </div>
    );
  }
};

export { ErrorHandler };

import { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import { DME, ImageChooser, ImageSetting, useEditorStore } from "dmeditor";
import { EntityLinks } from "../entity";
import { Button, Table } from "react-bootstrap";

const LinkSettings = (props: DME.SettingComponentProps<EntityLinks>) => {
  const {
    block: {
      data: { links },
    },
  } = props;

  const { updateSelectedBlock } = useEditorStore();

  const [showImageChooser, setShowImageChooser] = useState(false);

  const addImage = (imageInfo) => {
    const image = imageInfo[0].src;
    const newLinks = [...links, { image: image, link: "https://" }];
    updateSelectedBlock<EntityLinks>((data) => {
      data.links = newLinks;
    });
  };

  const updateLink = (index, value) => {
    const newLinks = [...links];
    newLinks[index] = { ...newLinks[index], link: value };
    updateSelectedBlock<EntityLinks>((data) => {
      data.links = newLinks;
    });
  };

  const updateImage = (index, image) => {
    const newLinks = [...links];
    newLinks[index] = { ...newLinks[index], image: image };
    updateSelectedBlock<EntityLinks>((data) => {
      data.links = newLinks;
    });
  };

  const deleteRow = (index) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    updateSelectedBlock<EntityLinks>((data) => {
      data.links = newLinks;
    });
  };

  return (
    <div>
      <Table>
        <thead>
          <th>Image</th>
          <th>Link</th>
        </thead>
        <tbody>
          {links.map((item, index) => (
            <tr>
              <td style={{ width: 100 }}>
                <ImageSetting
                  value={{ src: item.image }}
                  onChange={(info) => {
                    updateImage(index, info.src);
                  }}
                />
              </td>
              <td>
                <TextField
                  size="small"
                  defaultValue={item.link}
                  onChange={(e) => updateLink(index, e.target.value)}
                />
              </td>
              <td>
                <Button variant="light">
                  <i className="bi bi-trash" onClick={() => deleteRow(index)} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button
        variant="light"
        onClick={() => {
          setShowImageChooser(true);
        }}
      >
        Add new
      </Button>
      <ImageChooser
        visible={showImageChooser}
        value={[{ src: "", id: "" }]}
        onConfirm={addImage}
        onCancel={() => setShowImageChooser(false)}
      />
    </div>
  );
};

export { LinkSettings };

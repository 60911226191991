import { Alert, Button, Container, Table } from "react-bootstrap";
import { FetchWithAuth } from "digimaker-ui/util";
import { useEffect } from "react";

export const Users = () => {
  return (
    <div>
      <h2>Users</h2>
      <br />
      <Alert variant="warning">Coming soon</Alert>
    </div>
  );
};

import { dmeConfig, generalSettings } from "dmeditor";
import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";

const BridgeCarouselWidget: DME.Widget = {
  category: "bridge",
  icon: "bridge",
  name: "Bridge carousel",
  type: "bridge-carousel",
  widgetType: "mixed",
  events: {
    createBlock: (): DMEData.Block => {
      return {
        id: nanoid(),
        type: "bridge-carousel",
        style: { _: "default" },
        data: {},
        children: [
          {
            id: nanoid(),
            type: "carousel",
            style: { _: "default" },
            isEmbed: true,
            data: {
              animation: "default",
              autoPlay: true,
              items: [
                {
                  link: "https://www.google.com",
                  image:
                    "https://bridge.site.digimaker.com/var/images/z/zip/upload-918282482-carousel_image.jpg",
                  title: "",
                },
                {
                  link: "https://www.google.com",
                  image:
                    "https://bridge.site.digimaker.com/var/images/f/fbq/upload-295610395-voksen_kirke1.jpg",
                  title: "",
                },
              ],
            },
          },
          {
            id: nanoid(),
            type: "text",
            isEmbed: true,
            data: {
              value: [
                {
                  type: "paragraph",
                  children: [{ text: "Sample text" }],
                },
              ],
            },
          },
          {
            id: nanoid(),
            type: "list:button",
            isEmbed: true,
            style: { _: "default" },
            data: { direction: "horizontal", align: "center" },
            children: [
              {
                id: nanoid(),
                type: "button",
                data: { value: "Button1", link: "#" },
                style: { _: "primary" },
              },
              {
                id: nanoid(),
                type: "button",
                data: { value: "Button2", link: "#" },
                style: { _: "secondary" },
              },
            ],
          },
        ],
      };
    },
    embedConfig: {
      enabledSettings: (settings, styles, context) => {
        const resultSettings = settings.filter((setting) => {
          if (setting.category !== "style") {
            return true;
          }
          if (
            context.blockData.type === "button" ||
            context.blockData.type === "carousel"
          ) {
            return false;
          }
          return !setting.styleTags || setting.styleTags.includes("block");
        });

        let resultStyles = {};
        if (context.blockData.type === "button") {
          resultStyles = styles;
        }

        return { settings: resultSettings, enabledStyles: resultStyles };
      },
    },
    updateData: () => {},
  },
  settings: [],
};

export default BridgeCarouselWidget;

import { NavLink, Outlet } from "react-router-dom";

export const KlubbLayout = () => {
  return (
    <div>
      <div className="submenu-container">
        <ul className="submenu">
          <li className="menu-item">
            <NavLink to="iframe/medlemsregister">Medlemsreg</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="iframe/bridgemodule/newmember_list">
              {" "}
              Nye medlemmer
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="iframe/bridgemodule/member_history">
              Tidl. medlemmer
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink
              to="iframe/klubbmodule/memberlist"
              title="Medlemsliste/Fakturagrunnlag"
            >
              Medlemsliste
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="iframe/Mesterpoeng">Mesterpoeng</NavLink>
          </li>
          <li className="menu-item">
            <NavLink
              to="iframe/bridgemodule/serviceavgift/klubb"
              title="Serviceavgift/fakturaoversikt"
            >
              Serviceavgift
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="iframe/content/view/full/90946">Simultan</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="iframe/klubbmodule/upload_xml">
              Last opp resultatfil
            </NavLink>
          </li>
        </ul>
      </div>

      <div>
        <Outlet />
      </div>
    </div>
  );
};

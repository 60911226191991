/**
 * 
 * 
 * Use in DM Editor:
 * 
 import { defaultStyles } from './defaultStyles';

 for (const widget of Object.keys(defaultStyles)) {
    registerWidgetStyleOption(widget, [defaultStyles[widget]]);
 }
 */

 export const defaultStyles = {    
    accordion:{
        identifier:'_default', 
        name:'Default',
        cssStyle:``
    },
    tabs:{
            identifier:'_default', 
            name:'Default',
            cssStyle:`
            div[role='tablist']{
                border-bottom: 1px solid #cccccc;
                padding-left: 10px;

                 .dme-w-nav-item{
                  padding: 10px 15px;
                  border: none;
                  cursor: pointer;
                  background: white;
                  font-size: 1rem;
                  color:rgb(50, 111, 75);
                }

                .dme-w-nav-item:hover{
                    color: var(--tab-hover-color);
                }

                .dme-w-active{
                    font-weight: bold;
                }

                .dme-w-nav-item.dme-w-active{
                    border-top: 1px solid #cccccc;
                    border-left: 1px solid #cccccc;
                    border-right: 1px solid #cccccc;
                    border-bottom: 1px solid white;
                    margin-bottom: -1px;
                    border-radius: 4px 4px 0px 0px;
                }

            }            


            div[role='tabpanel']{
                padding: 10px 15px;
            }

            `
        },
    table:{
        identifier: '_default',
        name: 'Default',
        cssStyle:`
            .dme-viewmode-mobile & {
                max-width: 100%;
                overflow: auto;
            }
        `
    },    
    gallery:{
        cssStyle:`           
            .dme-w-pagination-container{
                margin-top: 20px;
            }

            .dme-w-pagination-item{
              padding: 5px 10px;
              border: 1px solid #cccccc;
              margin-left: 5px;
              display:inline-block;
             margin-bottom: 10px;
            }

            .dme-w-indicator{
                font-size: 14px;
                color: white;
                text-shadow: 1px 1px 1px #333333;
            }

            .dme-w-pagination-item-current{
              font-weight: bold;
            }            
        `
    }
}
import { registerWidget, registerWidgetStyleOption } from "dmeditor";

import BridgeCarousel from "./BridgeCarousel";
import definition from "./definition";

export default () => {
  registerWidget(definition, {
    render: BridgeCarousel,
  });
  registerWidgetStyleOption("bridge-carousel", [
    {
      identifier: "default",
      name: "Default",
      cssClasses: {
        root: "bridge-carousel",
      },
      cssStyle: `     
        width: var(--dme-container-width);
        margin-left: calc( ( var(--dme-main-width) - var(--dme-container-width) ) / 2 );
        .dme-wrapper-carousel{
          height: 85vh;
        }

        .preview-dmeditor & .dme-wrapper-carousel, .preview-dmeditor & .dme-wrapper-carousel .carousel-item img{
            height: 600px;
        }

        p{
          line-height: 1;
        }

        .dme-blocktype-carousel{
          button{
          }
        }
        
         .dme-carousel-item::after{
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.05);
          background-image: linear-gradient( to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.5) 100% );
        }
             
        .dme-carousel-item img{
          height: 85vh;
          width: inherit;
          max-width: none;
          object-fit: cover;          
        }

        & .bridge-carousel-buttons a {
            font-family: var(--font-Cambria) !important;
            font-weight: 700 !important;
            line-height: 1.5 !important;
        }

        .dme-viewmode-mobile &{

          .bridge-carousel-buttons > div > div{
            display:block;
          }
          .dme-wrapper-carousel{
            height: 70vh;
          }

          .dme-carousel-item img{
            height: 70vh;
          }


          & .bridge-carousel-buttons .dme-block-container:not(:last-child){
            margin-right: 0px;
          }

          .bridge-carousel-buttons .dme-block-container a{
            margin-bottom: 10px;
            min-width: 200px;
            margin-left: auto;
            margin-right: auto;
          }

          & > .bridge-carousel-buttons{
            bottom: 8% 
          }
        }
      
      & > div:not(:first-child){
        position: absolute;
        text-align: center;
        width: var(--dme-container-width);    
        z-index: 100;         
      }

      & > .bridge-carousel-text{
        top: 30%;        
        p{
          color: white;
          font-size: 2.5rem; 
          font-family: var(--font-Cambria);
          font-weight: bold;
        } 
      }


      & > .bridge-carousel-buttons{
        bottom: 20% 
      }

      & .bridge-carousel-buttons .dme-block-container:not(:last-child){
          margin-right: 15px;
      }

      & .bridge-carousel-buttons .dme-block-container a{
        padding: 8px 50px;
        border-radius: 0px;
        font-size: 20px;
      }
     
      .dme-carousel-image {
        width: 100%;
      }
      `,
    },
  ]);
  //   registerSettingComponent('bridge-carousel', SettingCarousel);
};

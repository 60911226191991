import React, { useEffect, useState, useRef } from "react";
import { H2, MKDiv } from "../commonStyle";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { DME } from "dmeditor";
import { EntityClubMembers } from "./entity";
import { siteEnv } from "../siteEnv";

const ClubMembers = (props: DME.WidgetRenderProps<EntityClubMembers>) => {
  const {
    blockNode: { data },
  } = props;

  const [medlemmerKlubbenList, setMedlemmerKlubbenList] = useState(() => {
    if (data.value) {
      return data.value;
    } else {
      return [
        { type: "Antall", value: 0 },
        { type: "Std", value: 0 },
        { type: "Jr", value: 0 },
        { type: "I", value: 0 },
        { type: "Lisens", value: 0 },
      ];
    }
  });

  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (!props.blockNode.serverData) {
      fetchData().then((newContent) => {
        setMedlemmerKlubbenList(newContent || []);
      });
    }
  }, []);

  return (
    <>
      <MKDiv className={"medlemmerKlubben"}>
        <H2 white={true}>{data.title}</H2>
        <div className="medlemmerKlubben-body">
          {medlemmerKlubbenList.map((item: any, index: any) => {
            return (
              item.value > 0 && (
                <React.Fragment key={index}>
                  <div className="medlemmerKlubben-group">
                    <div className="item-v">
                      {props.mode === "edit" && item.value}
                      {props.mode === "view" && (
                        <CountUp
                          start={focus ? 0 : undefined}
                          end={item.value || 0}
                          duration={1}
                        >
                          {({ countUpRef }) => (
                            <VisibilitySensor
                              partialVisibility
                              offset={{ bottom: 50 }}
                              onChange={(isVisible) => {
                                if (isVisible) {
                                  setFocus(true);
                                }
                              }}
                            >
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                        </CountUp>
                      )}
                    </div>

                    <div className="item-t">{item.type}</div>
                  </div>
                </React.Fragment>
              )
            );
          })}
        </div>
      </MKDiv>
    </>
  );
};

const fetchData = async () => {
  const clubID = siteEnv.clubID;
  const kretsID = siteEnv.kretsID;

  let result = {};
  if (clubID) {
    const res = await fetch(
      `https://www.bridge.no/bridgemodule/medlemmer_json?klubbid=${clubID}&limit=0&offset=0&returnType=json`,
      { method: "POST" }
    );
    const { html } = await res.json();
    result = html;
  } else if (kretsID) {
    const res = await fetch(
      `https://www.bridge.no/bridgemodule/medlemmer_json?kretsid=${kretsID}&limit=0&offset=0&returnType=json`,
      { method: "POST" }
    );
    const { html } = await res.json();
    result = html;
  }

  let typeConfing = {
    m_tot: "Antall",
    m_std: "Std",
    m_junior: "Jr",
    m_k: "I",
    m_lisens: "Lisens",
  };
  let newContent = Object.keys(result)
    .filter(
      (k: any) => (result[k].length > 0 || result[k] > 0) && typeConfing[k]
    )
    .map((item: any) => {
      return {
        type: typeConfing[item],
        value: result[item],
      };
    });
  return newContent;
};

const onServerSideLoad = async (blockData: any) => {
  const newContent = await fetchData();
  if (newContent) {
    blockData.data.value = newContent;
  }
  blockData.serverData = true;
};

export { ClubMembers, onServerSideLoad };

import { create } from "zustand";

const selectedSiteKey = "selected_site";

export type SiteInfo = {
  id: number;
  host: string;
  basePath: string;
  name: string;
  clubID: number;
};

type Site = {
  site: SiteInfo;
  sites: Array<any>;
  currentContent;
  user: any;
};
type Action = {
  setSite: (site: SiteInfo) => void;
  setUser: (user: any) => void;
  setSites: (sites: Array<any>) => void;
  setCurrentContent: (content) => void;
  clearState: () => void;
};

const initedSite = { id: 0, host: "", name: "", clubID: 0, basePath: "" };

export const getSavedSiteID = () => {
  const value = localStorage.getItem(selectedSiteKey);
  if (value) {
    return parseInt(value);
  } else {
    return parseInt(process.env.REACT_APP_SITE_TEMPLATE_PARENT || "");
  }
};

export const clearSavedSiteID = () => {
  localStorage.removeItem(selectedSiteKey);
};

const useSiteStore = create<Site & Action>((set) => ({
  site: initedSite,
  sites: [],
  currentContent: null,
  user: null,
  setUser: (user: any) => set((state) => ({ user: user })),
  setSite: (site: SiteInfo) => {
    localStorage.setItem(selectedSiteKey, site.id + "");
    set((state) => ({ site: site }));
  },
  setSites: (sites: Array<any>) => set((state) => ({ sites: sites })),
  setCurrentContent: (content) => set((state) => ({ currentContent: content })),
  clearState: () =>
    set((state) => ({
      site: initedSite,
      currentContent: null,
      user: null,
      sites: [],
    })),
}));

export default useSiteStore;

import { FetchWithAuth } from "digimaker-ui/util";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import useSiteStore from "../store/useSiteStore";
import { useNavigate } from "react-router";
import { initSite } from "./initSite";

export const ChangeSite = () => {
  const {
    site: { id: siteID },
    sites,
    setSite,
  } = useSiteStore();
  const navigate = useNavigate();

  const selectSite = async (v) => {
    if (v) {
      const rootID = parseInt(v);
      const selected = sites.find((item) => item.location.id === rootID);
      await initSite(sites, rootID);
      setSite({
        id: rootID,
        name: selected["_club"]["site_name"],
        host: selected["_club"]["host"],
        clubID: selected["_club"]["org_id"],
        basePath: selected["_club"]["basePath"],
      });
      navigate("/");
    }
  };

  const siteTypes = ["independent", "club", "krets"];

  return sites.length > 1 ? (
    <div>
      <Form.Select
        size="sm"
        aria-label="Select site"
        onChange={(e) => selectSite(e.target.value)}
      >
        {siteTypes.map((siteType, siteTypeIndex) => {
          const filteredSites = sites.filter(
            (item) => item["_club"].site_type === siteType
          );
          if (filteredSites.length > 1) {
            return (
              <>
                <option value={""} disabled>
                  ---&nbsp;
                  {
                    {
                      club: "Klubb",
                      krets: "Krets",
                      independent: "Independent",
                    }[siteType]
                  }{" "}
                  ---
                </option>
                {filteredSites.map((itemSite: any) => (
                  <option
                    selected={siteID === itemSite.location.id}
                    value={itemSite.location.id}
                  >
                    {(() => {
                      const siteRecord = itemSite["_club"];
                      switch (siteRecord.site_type) {
                        case "club":
                          return `${siteRecord.org_name} (${siteRecord.org_id})`;
                        case "krets":
                          return `${siteRecord.org_name} (${siteRecord.org_id})`;
                        case "independent":
                          return siteRecord.site_name;
                        default:
                          break;
                      }
                    })()}
                  </option>
                ))}
              </>
            );
          } else {
            return <></>;
          }
        })}
      </Form.Select>
    </div>
  ) : (
    <></>
  );
};

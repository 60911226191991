import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { FetchWithAuth } from "digimaker-ui/util";

const Hide = (props: any) => {
  const {
    params: { content },
  } = props;

  console.log(props.params);
  const handle = (e) => {
    e.preventDefault();
    const status = content.location.is_hidden ? "1" : "0";
    FetchWithAuth("content/hide/" + content.location.id + "/" + status).then(
      (data) => {
        if (data.error === false) {
          props.params.afterAction(true);
        }
      }
    );
  };

  return (
    <a
      style={{ paddingLeft: 5 }}
      href="#"
      onClick={handle}
      title={content.location.is_hidden ? "Show" : "Hide"}
    >
      {content.location.is_hidden && <VisibilityOffIcon />}
      {!content.location.is_hidden && <VisibilityIcon />}
    </a>
  );
};

export { Hide };

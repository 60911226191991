import { DME, useEditorStore } from "dmeditor";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { css } from "@emotion/css";
import { Slider } from "@mui/material";
import { EntityAnnualMeeting } from "./entity";
import { annualMeetingStyles as styles } from "./style";
import { FetchWithAuth } from "digimaker-ui/util";
import { serverFetchWithAuth } from "../util";
import { siteEnv } from "../siteEnv";

const { useState, useEffect } = React;

export interface AnnualReport {
  id: number;
  title: string;
  year: {
    text: string;
    value: string;
  };
  body?: Array<{
    name: string;
    path: string;
  }>;
}

const AnnualMeeting = (props: DME.WidgetRenderProps<EntityAnnualMeeting>) => {
  const {
    blockNode: {
      data: { settings },
    },
  } = props;

  interface AnnualReportData {
    error: boolean;
    data: {
      count: number;
      list: AnnualReport[];
    };
  }

  const [width, setWidth] = useState(settings.width ?? 600);
  const [height, setHeight] = useState(settings.height ?? 300);
  const [header, setHeader] = useState(settings.header ?? "Årsmøte");
  const [annualReports, setAnnualReports] = useState<AnnualReport[]>(
    props.blockNode.data.value || []
  );
  const { updateSelectedBlock } = useEditorStore();

  const fetchAnnualReports = async () => {
    try {
      const parent = siteEnv.folderAnnualMeeting;
      const response = await FetchWithAuth(
        "content/list/annual_report?parent=" + parent + "&level=1"
      );
      if (!response.error) {
        const typedResponse = response as AnnualReportData; // Type assertion
        setAnnualReports(typedResponse.data.list);
      }
    } catch (error) {
      console.error("Error fetching annual reports:", error);
    }
  };

  useEffect(() => {
    if (!props.blockNode.serverData) {
      fetchAnnualReports();
      setWidth(settings.width ?? 1070);
      setHeight(settings.height ?? 300);
      setHeader(settings.header ?? "Årsmøte");
    }
  }, [settings]);

  const updateWidth = (e, v) => {
    updateSelectedBlock<EntityAnnualMeeting>((data) => {
      data.settings.width = v as number;
    });
  };

  const updateHeight = (e, v) => {
    updateSelectedBlock<EntityAnnualMeeting>((data) => {
      data.settings.height = v;
    });
  };

  const updateHeader = (e, v) => {
    updateSelectedBlock<EntityAnnualMeeting>((data) => {
      data.settings.header = v;
    });
  };

  const getFileLabel = (index) => {
    switch (index) {
      case 0:
        return "Styrets beretning";
      case 1:
        return "Årsregnskap";
      case 2:
        return "Revisjonsberetning";
      case 3:
        return "Årsmøteprotokoll";
      default:
        return "Ukjent";
    }
  };

  return (
    <div className={styles.widget}>
      <div className={styles.container}>
        {/* tittel rendering med egen styling */}
        <h2 className={styles.header}>{header}</h2>
        {annualReports.map((report, index) => (
          <Accordion
            key={index}
            defaultExpanded={index === 0}
            className={styles.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <h3 className={styles.h3}>{report.year.text}</h3>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetails}>
              {report.body &&
                report.body.map((file, fileIndex) => (
                  <div key={fileIndex} className={styles.rowContainer}>
                    {file && file.path && (
                      <div>
                        <a
                          href={`https://bridge.c.digimaker.com/var/${file.path}`}
                          className={styles.link}
                        >
                          <span className={styles.label}>
                            {getFileLabel(fileIndex)}
                          </span>
                        </a>
                      </div>
                    )}
                  </div>
                ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

const onServerSideLoad = async (blockData: any) => {
  const limit = -1;

  const parent = siteEnv.folderAnnualMeeting;
  const bodyObj = `query{
        annual_report(filter:{_location_parent_id:${parent}},limit:${limit}, sort:["year desc"]){
          id,
          title,
          year,
          body,
          location{id,hierarchy}, 
      }
      }`;
  const jsonData = await serverFetchWithAuth(bodyObj);
  const list = jsonData.data.annual_report;
  for (const i in list) {
    list[i].year = { text: list[i].year, value: list[i].year };
  }
  blockData.data.value = list;
  blockData.serverData = true;
};

export { AnnualMeeting, onServerSideLoad };

import { DME, DMEData, generalSettings } from "dmeditor";
import { nanoid } from "nanoid";
import { EntityClubList } from "./entity";
const definition: DME.Widget = {
  category: "bridge",
  icon: "bridge",
  name: "Clubs dropdown select",
  type: "bridge-club-dropdown",
  events: {
    createBlock: (): DMEData.Block<EntityClubList> => {
      return {
        id: nanoid(),
        type: "bridge-club-dropdown",
        style: { width: "fullwidth" },
        data: {
          title: "BRIDGE KLUBBER",
          clubs: [],
        },
      };
    },
    updateData: () => {},
  },
  settings: [
    {
      name: "Title",
      property: ".title",
      settingComponent: "input",
    },
  ],
};

export default definition;

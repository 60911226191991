import { dmeConfig } from "dmeditor";
import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";
import { EntityTopNews } from "./entity";
const definition: DME.Widget = {
  category: "bridge",
  icon: "bridge",
  name: "Top news",
  type: "bridge-top-news",
  events: {
    createBlock: (): DMEData.Block<EntityTopNews> => {
      return {
        id: nanoid(),
        type: "bridge-top-news",
        data: {
          value: [],
          moreLink: "/artikler",
          settings: {
            limit: 3,
          },
        },
      };
    },
    updateData: () => {},
  },
  settings: [
    {
      name: "Items",
      property: "settings.limit",
      settingComponent: "range",
      parameters: {
        min: 2,
        max: 4,
      },
    },
    {
      name: "Link",
      property: ".moreLink",
      settingComponent: "input",
    },
  ],
};

export default definition;

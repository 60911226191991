import { Button, Container, Table } from "react-bootstrap";
import useSiteStore from "../../store/useSiteStore";
import { FetchWithAuth } from "digimaker-ui/util";
import { useEffect } from "react";

export const Setup = () => {
  const { site } = useSiteStore();

  return (
    <div>
      <h2>Oppsett</h2>
      <Container>
        <Table>
          <tr>
            <td>Navn</td>
            <td>{site.name}</td>
          </tr>
          <tr>
            <td>Klubb ID</td>
            <td>{site.clubID}</td>
            <td></td>
          </tr>
          <tr>
            <td>Url</td>
            <td>
              <a
                href={"https://" + (site.host || "bridge.no/" + site.basePath)}
                rel="noreferrer"
                target="_blank"
              >
                https://{site.host || "bridge.no/" + site.basePath}
              </a>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Domain</td>
            <td>{site.host}</td>
            <td>
              {/* <Button variant="link" title="Update">
                <i className="bi bi-pencil" />
              </Button> */}
            </td>
          </tr>
        </Table>
      </Container>
    </div>
  );
};

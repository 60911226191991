import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";
import { EntityMemberList } from "./entity";
const definition: DME.Widget = {
  category: "bridge",
  icon: "bridge",
  name: "Member list",
  type: "bridge-member-list",
  events: {
    createBlock: (): DMEData.Block<EntityMemberList> => {
      return {
        id: nanoid(),
        type: "bridge-member-list",
        data: {},
      };
    },
    updateData: () => {},
  },
  settings: [],
};

export default definition;

import { css } from "@emotion/css";

export const CalendarStyle = {
  category1Tile: css`
    background-color: lightyellow !important;
  `,
  category2Tile: css`
    background-color: lightblue !important;
  `,
  category3Tile: css`
    background-color: lightgreen !important;
  `,
  category4Tile: css`
    background-color: pink !important;
  `,
  diverseEventTile: css`
    background-color: grey !important;
  `,
  widgetStyle: css`
    font-family: "Pavanam", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    box-sizing: border-box;
    padding: 20px;
    overflow: hidden;
  `,
  listItemStyle: css`
    font-family: "Pavanam", sans-serif;
    list-style-type: none;
    text-align: left; // Endret fra center til left for bedre lesbarhet
    padding-left: 0; // Fjernet unødvendig padding
    padding: 10px;
    border: 1px solid black;
    margin-bottom: 10px;
  `,
  boxStyle: css`
    font-family: "Pavanam", sans-serif;
    border: 1px solid grey;
    padding: 20px;
    margin-top: 54px;
    width: 35%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  listStyle: css`
    font-family: "Pavanam", sans-serif;
    padding: 0;
    max-height: 800px;
    list-style-type: none;
    overflow-y: auto;
  `,
  filterContainer: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px; // Legger til litt plass mellom filterelementene
    margin-bottom: 20px; // Margin for å separere filtreringsdelen fra resten
  `,
  container: css`
    display: flex;
    width: 100%;
    align-items: flex-start;
  `,

  calender: css`
    width: 65%;
  `,

  mobileWidgetStyle: css`
    flex-direction: column-reverse;
    width: 100%;
  `,

  mobileEventsStyle: css`
    margin-left: 9px;
    width: 94%;
  `,

  mobileCalenderStyle: css`
    margin-bottom: 80px;
    width: 100%;
  `,
};

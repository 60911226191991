import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";
import { EntityResult } from "./entity";
const definition: DME.Widget = {
  category: "bridge",
  icon: "bridge",
  name: "Tournament result",
  type: "bridge-result",
  events: {
    createBlock: (): DMEData.Block<EntityResult> => {
      return {
        id: nanoid(),
        type: "bridge-result",
        data: {},
      };
    },
    updateData: () => {},
  },
  settings: [
    {
      name: "Folder id",
      settingComponent: "input",
      property: ".folder",
      parameters: {
        updateOnUnfocus: true,
      },
      description:
        "Folder name under ruter, mostly a club id or kret id. Eg. 704 or 9100, or 704,9100(support multiple, separated by ,). Empty will use current site(klubb or krets).",
    },
  ],
};

export default definition;

import { registerWidget } from "dmeditor";
import definition from "./definition";
import { ClubMembers, onServerSideLoad } from "./ClubMembers";

const register = () => {
  registerWidget(definition, {
    render: ClubMembers,
    onServerSideLoad: onServerSideLoad,
  });
};

export default register;

import { ViewContent, RenderFields } from "digimaker-ui";
import { FetchWithAuth } from "digimaker-ui/util";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import useSiteStore from "../../../store/useSiteStore";

export const SiteSettings = () => {
  const [content, setContent] = useState<any>(null);
  const [mode, setMode] = useState("view");

  const { site } = useSiteStore();

  const fetchData = () => {
    FetchWithAuth("content/get/" + site.id).then((data) =>
      setContent(data.data)
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    const dataObject = {};
    for (let key of Array.from(form.keys())) {
      dataObject[key] = form.get(key);
    }

    let url = "content/update/" + content.location.id;
    FetchWithAuth(url, {
      method: "POST",
      body: JSON.stringify(dataObject),
    }).then((data) => {
      if (data.error === false) {
        fetchData();
        setMode("view");
      } else {
        window.alert(data.data.message);
      }
    });
  };

  return (
    <div>
      <h2 className="h2" style={{ marginTop: 20 }}>
        Nettstedsinnstillinger
      </h2>
      {mode === "view" && (
        <div key={content ? content.metadata.modified : ""}>
          <div className="text-right">
            <Button size="sm" variant="link" onClick={() => setMode("edit")}>
              <i className="bi bi-pencil"></i> &nbsp; Redigere
            </Button>
          </div>
          {content && <ViewContent content={content} />}
        </div>
      )}
      {mode === "edit" && (
        <div>
          {content && (
            <div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="text-right">
                  <Button
                    type="submit"
                    size="sm"
                    onClick={() => setMode("edit")}
                  >
                    <i className="bi bi-send"></i> Send inn
                  </Button>
                  <Button
                    size="sm"
                    variant="text"
                    onClick={() => setMode("view")}
                  >
                    Avbryt
                  </Button>
                </div>
                <RenderFields
                  type={content.metadata.contenttype}
                  editFields={["menu_icon", "logo"]}
                  validation=""
                  mode="edit"
                  data={content}
                  afterField={() => {}}
                />
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

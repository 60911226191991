import { registerWidget } from "dmeditor";
import definition from "./definition";
import { TopNews, onServerSideLoad } from "./TopNews";

const register = () => {
  registerWidget(definition, {
    render: TopNews,
    onServerSideLoad: onServerSideLoad,
  });
};

export default register;

import { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router";
import { NavLink } from "react-router-dom";
import { CSS } from "@dnd-kit/utilities";

import { FetchWithAuth } from "digimaker-ui/util";
import { ButtonNewPage } from "../../../components/ButtonNewPage";
import useSiteStore from "../../../store/useSiteStore";
import { Space } from "../../../components/Space";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import { SortableMenu } from "../../../components/util/SortableMenu";
import { nbfEnv } from "shared/src/widgets/siteEnv";

const iconMap = {
  article: "bi-file-earmark-richtext",
  image: "bi-images",
  file: "bi-file-earmark-richtext",
  event: "bi-calendar-event",
  annual_report: "bi-file-earmark-bar-graph",
};

export const LayoutWebsite = (props: {
  isRefresh: boolean;
  refreshEnd?: any;
  afterAction?: any;
}) => {
  const [list, setList] = useState([] as any[]);
  const [libraries, setLibraries] = useState([] as any[]);
  const navigate = useNavigate();
  const {
    currentContent,
    site: { id: siteID },
    user,
  } = useSiteStore();

  const fetchMenu = () => {
    FetchWithAuth(
      "content/list/page?parent=" + siteID + "&sortby=priority desc"
    ).then((resp: any) => {
      if (resp.error === false) {
        setList(resp.data.list);
      }
    });
  };

  useEffect(() => {
    fetchMenu();

    FetchWithAuth(
      "content/list/folder?parent=" + siteID + "&level=1&limit=20"
    ).then((resp: any) => {
      if (resp.error === false) {
        const folderList = resp.data.list.filter((item) => item.folder_type);
        setLibraries(folderList);
      }
    });

    if (props.refreshEnd) {
      props.refreshEnd(false);
    }
  }, [props.isRefresh]);

  const afterActions = (redirect: boolean) => {
    if (redirect) {
      navigate("/website");
      if (props.refreshEnd) {
        props.refreshEnd(true);
      }
    }
  };
  return (
    <>
      <div className="website-layout">
        <div className="main">
          {currentContent &&
            ["site", "page"].includes(currentContent.metadata.contenttype) && (
              <div className="submenu-container">
                <div className="right" style={{ padding: "0px 10px" }}>
                  <ButtonNewPage />
                </div>
                <SortableMenu list={list} onRefresh={() => fetchMenu()} />
              </div>
            )}
          <Outlet />
        </div>
        <div className="side">
          <div>
            <Space />
            <div>
              <NavLink to={"/website/main/" + siteID}>
                <i className="bi bi-file-earmark-richtext"></i> Sider
              </NavLink>
            </div>
            <Space />
            {libraries.map((item) => (
              <div>
                <NavLink to={"/website/main/" + item.location.id}>
                  <i
                    className={
                      "bi " +
                      (iconMap[item.folder_type.value] ||
                        "bi-file-earmark-richtext")
                    }
                  ></i>{" "}
                  {item.metadata.name}
                </NavLink>
              </div>
            ))}
            <Space />
            <div>
              <NavLink to={"/website/site-settings"}>
                <i className="bi bi-file-earmark-richtext"></i>{" "}
                Nettstedsinnstillinger
              </NavLink>
            </div>
            <div>
              <a href={"/changelog.txt"} target="_blank">
                Change log <i className="bi bi-box-arrow-up-right"></i>
              </a>
            </div>
            {user.roles.includes("bridge-admin") && (
              <>
                <div>
                  <h3
                    style={{ marginTop: 20, fontSize: "16px", marginLeft: 5 }}
                  >
                    For all sites
                  </h3>
                </div>
                <div>
                  <NavLink to={"/website/main/" + nbfEnv.folderTemplate}>
                    <i className="bi bi-file-earmark-richtext"></i> Maler
                  </NavLink>
                </div>
                <div>
                  <NavLink to={"/website/main/" + nbfEnv.folderLink}>
                    <i className="bi bi-link-45deg"></i> NBF lenker
                  </NavLink>
                </div>
                <div>
                  <NavLink to={"/website/footer"}>
                    <i className="bi bi-file-earmark-richtext"></i> Bunntekst
                  </NavLink>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Droppable = (props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  const style = {
    opacity: isOver ? 1 : 0.5,
  };

  return (
    <div ref={setNodeRef} style={style}>
      {props.children}
    </div>
  );
};

const Draggable = (props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });
  const style = {
    // Outputs `translate3d(x, y, 0)`
    transform: CSS.Translate.toString(transform),
  };

  return (
    <button ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {props.children}
    </button>
  );
};

import { Container } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";

export const AdministrationLayout = () => {
  return (
    <div>
      <div className="submenu-container">
        <ul className="submenu">
          <li className="menu-item">
            <NavLink to="setup">Domene</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="forward-email">Videresende epost</NavLink>
          </li>
          <li className="menu-item">
            <NavLink to="users">Brukere</NavLink>
          </li>
        </ul>
      </div>
      <div>
        <Container>
          <Outlet />
        </Container>
      </div>
    </div>
  );
};

import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";
import { EntityLinks } from "./entity";
const definition: DME.Widget = {
  category: "bridge",
  icon: "bridge",
  name: "Links",
  type: "bridge-links",
  events: {
    createBlock: (): DMEData.Block<EntityLinks> => {
      return {
        id: nanoid(),
        type: "bridge-links",
        style: {},
        data: {
          title: "Sponsors",
          nbfLinks: [],
          links: [
            {
              title: "Test",
              link: "ffff",
              image:
                "https://bridge.site.digimaker.com/var/images/t/txg/upload-524950872-grasrotandelen.png",
            },
          ],
        },
      };
    },
    updateData: () => {},
  },
  settings: [
    {
      name: "Title",
      property: ".title",
      settingComponent: "input",
    },
    {
      name: "",
      property: "",
      custom: true,
      settingComponent: "bridge-links",
    },
  ],
};

export default definition;

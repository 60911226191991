import { ViewContent } from "digimaker-ui";
import { FetchWithAuth } from "digimaker-ui/util";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";

export const View = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [content, setContent] = useState(null as any);

  useEffect(() => {
    FetchWithAuth("content/get/" + params.id).then((resp: any) => {
      if (resp.error === false) {
        const content = resp.data;
        setContent(content);
      } else {
        setContent(null);
      }
    });
  }, [params.id]);
  return (
    <div className="preview-content">
      {content && <ViewContent key={content.id} content={content} />}
      {!content && <div className="loading"></div>}
      <br />
      <div className="right">
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={() => window.history.back()}
        >
          Tilbak
        </Button>
      </div>
    </div>
  );
};

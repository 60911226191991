import React, { ReactNode } from 'react';
import { useParams } from 'react-router';
import { useNavigate, useLocation} from "react-router-dom";


export const DMRouteRender = (props:{render:(params:any,navigate:any,location:any)=>JSX.Element})=>{
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const A = props.render;

    return  A(params,navigate,location);
}
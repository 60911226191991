import { registerSettingComponent, registerWidget } from "dmeditor";
import definition from "./definition";
import { NewsList, onServerLoad } from "./NewsList";

const register = () => {
  registerWidget(definition, {
    render: NewsList,
    onServerSideLoad: onServerLoad,
  });
};

export default register;

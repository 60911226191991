import { Alert } from "react-bootstrap";

const BrowseLink = () => {
  return (
    <div>
      <Alert>
        Note: browsing is not support yet, please 'Link' tab instead. <br />
        We will soon support browsing page / article / file .
      </Alert>
    </div>
  );
};

export { BrowseLink };

import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

export const ClubMain = () => {
  const params = useParams()["*"];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, [params]);

  const loaded = (e) => {
    // console.log(e.target.contentWindow.document);
    setLoading(false);
  };

  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div className="loading" style={{ left: "46%", top: 50 }}></div>
      )}
      <iframe
        onLoad={loaded}
        style={{ width: "1200px", minHeight: "100vh" }}
        src={"https://klubb.bridge.no/" + params}
      ></iframe>
    </div>
  );
};
